<template>
  <div class="separator"></div>
</template>

<style lang="scss" scoped>
.separator {
  background-color: #3d3d3d;
  height: 2px;
  margin: 1.5rem 0;
}
</style>
